import axios from "axios";

const useEditUserSubType = () => {
  const editUserSubType = async (
    user,
    startDate,
    endDate,
    selectSubType,
    status,
    closeFlyout,
    createToast
  ) => {
    try {
      console.log(selectSubType[0].label);
      const response = await axios.post(
        "https://sinogani-api-server.onrender.com/api/userSubType",
        {
          email: user.email,
          startDate: startDate.format("L"),
          endDate: endDate.format("L"),
          selectSubType: selectSubType[0].label,
          status: status,
        }
      );

      if (response.status === 200) {
        createToast({
          title: "Subscription updated sucessfully.",
          type: "success",
        });
        console.log("Subscription updated successfully.");
        closeFlyout(true);
      }
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };

  return { editUserSubType };
};

export default useEditUserSubType;
