import React from 'react'
import { ModalProductDateAndEditContainer, ModalProductDivider, ModalProductFirstSectionContainer, ModalProductGlobalContainer, ModalProductImage, ModalProductImageEdition, ModalProductListContainer, ModalProductName, ModalProductOtherSectionContainer, ModalProductPrice, StatusContainer } from './ModalProduct.element';
//import { format } from 'date-fns';
import { EuiBadge } from '@elastic/eui';

const ModalProduct = ({ product, handleChangeModalState  }) => {


  const icon = require('../images/more.png');

  return (
    <ModalProductGlobalContainer>
        <ModalProductListContainer>
            <ModalProductFirstSectionContainer>
                <ModalProductImage src={product.images[0]} />

                <ModalProductName>{product.productName}</ModalProductName>
            </ModalProductFirstSectionContainer>


            <ModalProductOtherSectionContainer>
                <ModalProductPrice>{product.price}</ModalProductPrice>
            </ModalProductOtherSectionContainer>


            <ModalProductOtherSectionContainer>
                <ModalProductPrice>{product.category}</ModalProductPrice>
            </ModalProductOtherSectionContainer>


            <ModalProductOtherSectionContainer>
                <ModalProductDateAndEditContainer>
                    <StatusContainer>
                      {/*<ModalProductPrice>{formattedDate}</ModalProductPrice>*/
                         product.stock ? <EuiBadge color="success">In stock</EuiBadge> : <EuiBadge color="danger">Stock out</EuiBadge>
                      }
                    </StatusContainer>
                    <ModalProductImageEdition onClick={handleChangeModalState} src={icon} />
                </ModalProductDateAndEditContainer>
            </ModalProductOtherSectionContainer>
        </ModalProductListContainer>
        <ModalProductDivider />
    </ModalProductGlobalContainer>
  )
}

export default ModalProduct
