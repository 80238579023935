import React from "react";
import {
  ProductContainer,
  ProductHeadContainer,
  ProductImg,
  ProductMinOrderContainer,
  ProductMinOrderIcon,
  ProductMinOrderText,
  ProductNamePrice,
  ProductNameText,
  ProductMinOrderLeText,
  ProductMinOrderContainers,
  ProductMinOrderTexts,
  ProductMinOrderLeTexts,
  OverLayProduct,
  AddIcon,
  AddCartContainer,
  AddIcons,
} from "./Product.element";
import { useDispatch, useSelector } from "react-redux";
import ReactWhatsapp from "react-whatsapp";
import { toast } from "react-hot-toast";
import { addToCart } from "../stores/shopping-cart/cartSlice";

const Product = ({ product, changeState, devise }) => {
  const icon1 = require("../images/panier.png");
  const icon2 = require("../images/minuteur.png");
  const icon3 = require("../images/whatsapp.png");
  const cartIcon = require("../images/cart.png");

  const dispatch = useDispatch();

  const cartItems = useSelector((state) => state.cart.cartItems);

  const insideCart = cartItems.some((p) => p.uid === product.uid);

  const handleCartContainerClick = (event) => {
    if (insideCart) {
      toast.success("Product already in cart");
    } else {
      toast.success("Product added in cart");
    }

    const { uid, productName, description, price, images, contact } = product;

    const image = images[0];

    const totalPrice = price;

    dispatch(
      addToCart({
        uid,
        productName,
        description,
        price,
        image,
        contact,
        totalPrice,
      })
    );

    event.stopPropagation();
    event.preventDefault();
  };

  const userLocale = navigator.language || "en-US";
  const currencyFormatter = new Intl.NumberFormat(userLocale, {
    style: "currency",
    currency: devise,
    currencyDisplay: "symbol",
  });
  const message = `Hello, I would like to place an order for the product "${product.productName}" found on the website www.sinogani.com. Could you please provide me with more information regarding the price, available quantity, and delivery terms? Thank you!`;

  return (
    <ProductContainer onClick={() => changeState(product)}>
      <ProductHeadContainer translate="no">
        <ProductImg loading="lazy" src={product.images[0]} />

        <OverLayProduct>
          <AddCartContainer>
            <ReactWhatsapp
              number="+86 187 1770 9574"
              message={message}
              className="react__product__whatsapp"
              hidden
            >
              <AddIcon src={icon3} />
            </ReactWhatsapp>
          </AddCartContainer>

          <AddCartContainer onClick={handleCartContainerClick}>
            <AddIcons src={cartIcon} />
          </AddCartContainer>
        </OverLayProduct>
      </ProductHeadContainer>
      <ProductNameText>{product.productName}</ProductNameText>
      <ProductNamePrice>
        {currencyFormatter.format(product.price)}
      </ProductNamePrice>
      <ProductMinOrderContainer>
        <ProductMinOrderIcon src={icon1} />
        <ProductMinOrderText>Minimum Order:</ProductMinOrderText>
        <ProductMinOrderLeText>{product.minOrder}</ProductMinOrderLeText>
      </ProductMinOrderContainer>

      <ProductMinOrderContainers>
        <ProductMinOrderIcon src={icon2} />
        <ProductMinOrderTexts>Delivery Time:</ProductMinOrderTexts>
        <ProductMinOrderLeTexts>{product.deleveryDate}</ProductMinOrderLeTexts>
      </ProductMinOrderContainers>
    </ProductContainer>
  );
};

export default Product;
