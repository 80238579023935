import React, { useState } from "react";
import moment from "moment";
import {
  EuiFlyout,
  EuiFlyoutHeader,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiSwitch,
  EuiTitle,
} from "@elastic/eui";
import MeetingDateField from "../components/FormComponents/MeetingDateField";
import CreateMeetingButtons from "../components/FormComponents/CreateMeetingButtons";
import SubTypeUserField from "../components/FormComponents/SubTypeUserField";
import useAuths from "../hooks/useAuths";
import UseToats from "../hooks/UseToats";
import useEditUserSubType from "../hooks/useEditUserSubType";

const EditsFlayout = ({ closeFlyout, user }) => {
  useAuths();
  const subType = [
    {
      label: "STANDARD",
      value: "STANDARD",
    },
    {
      label: "VIP",
      value: "VIP",
    },
    {
      label: "GOLD",
      value: "GOLD",
    },
    {
      label: "PRENUIM",
      value: "PRENUIM",
    },
  ];
  const [createToast] = UseToats();

  const [selectSubType, setSelectSubType] = React.useState([
    { label: user.subType, value: user.subType },
  ]);
  const [endDate, setEndDate] = React.useState(moment(user.subEndStatusDate));

  const [startDate, setStartDate] = React.useState(moment(user.subBeginDate));

  const [status, setStatus] = useState(user.cancel);
  const [showErrors] = useState({
    subType: {
      show: false,
      message: [],
    },
    selectUser: {
      show: false,
      message: [],
    },
  });

  const onSubTypeChange = (selectedOptions) => {
    setSelectSubType(selectedOptions);
  };

  const { editUserSubType } = useEditUserSubType();

  const editMeeting = async () => {
    await editUserSubType(
      user,
      startDate,
      endDate,
      selectSubType,
      status,
      closeFlyout,
      createToast
    );
  };

  return (
    <EuiFlyout ownFocus onClose={() => closeFlyout()}>
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="m">
          <h2>{user.email}</h2>
        </EuiTitle>
      </EuiFlyoutHeader>
      <EuiFlyoutHeader>
        <EuiForm>
          <SubTypeUserField
            label="Select SubType"
            options={subType}
            onChange={onSubTypeChange}
            selectedOptions={selectSubType}
            singleSelection={{ asPlainText: true }}
            isClearable={false}
            placeholder="Select a subcription"
            isInvalid={showErrors.subType.show}
            error={showErrors.subType.message}
          />
          <MeetingDateField selected={startDate} setStartDate={setStartDate} />
          <MeetingDateField selected={endDate} setStartDate={setEndDate} />
          <EuiFormRow
            display="columnCompressedSwitch"
            label="Cancel Subscription"
          >
            <EuiSwitch
              showLabel={false}
              checked={status}
              label="Cancel Meeting"
              onChange={(e) => setStatus(e.target.checked)}
            />
          </EuiFormRow>
          <EuiSpacer />
          <CreateMeetingButtons
            createMeeting={editMeeting}
            isEdit
            closeFlyout={closeFlyout}
          />
        </EuiForm>
      </EuiFlyoutHeader>
    </EuiFlyout>
  );
};

export default EditsFlayout;
