import React from 'react'
import Header from '../Header';
import moment from 'moment';
import {
    EuiBadge,
    EuiBasicTable,
    EuiFlexGroup,
    EuiButtonIcon,
    EuiFlexItem,
    EuiPanel,
  } from "@elastic/eui";
import useAuths from '../../hooks/useAuths';
import EditsFlayout from "../../Themes/EditsFlayout";
import axios from 'axios';

const ManageUserSub = () => {
  useAuths();

  const [users, setUsers] = React.useState([]);
  const [showEditFlyout, setShowEditFlyout] = React.useState(false);
  const [editUserSub, setEditUserSub] = React.useState();
  
  const getUserList = async () => {

    let data;

    try {
      const response = await axios.get('https://sinogani-api-server.onrender.com/users-list');
      data = response.data;
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }

    return data;

  }

  const openEditFlyout = (user) => {
      setShowEditFlyout(true);
      setEditUserSub(user);
      console.log(editUserSub);
    };
  
    const closeEditFlyout = (dataChanged = false) => {
      setShowEditFlyout(false);
      setEditUserSub(undefined);
      if (dataChanged) getUserList();
    };


  const columns = [
    {
      field: "userName",
      name: "User Name",
    },
    {
      field: "email",
      name: "User Email",
    },
    {
      field: "createDate",
      name: "Date Created",
    },
    {
      field: "subType",
      name: "Subcription Type",
    },
    {
      field: "subBeginDate",
      name: "Subcription Begin Date",
    },
    {
      field: "subEndStatusDate",
      name: "Subcription End Date",
    },
    {
      field: "",
      name: "Subscription Status",
      render: (user) => {
          if  (!user.cancel) {

              if (user.subStatus === true && moment(user.subBeginDate).isAfter(moment().format("L"))) {
                  return <EuiBadge color="primary">Upcoming</EuiBadge>
              }else if (user.subStatus === true && moment(user.subEndStatusDate).isAfter(moment().format("L"))){
                  return <EuiBadge color="success">Active</EuiBadge>
              }else {
                  return <EuiBadge color="default">Ended</EuiBadge>;
              }

          }else {
              return <EuiBadge color="danger">Cancelled</EuiBadge>;
          }

      },
    },
    {
      field: "",
      name: "Edit",
      render: (user) => {
        return(
          <EuiButtonIcon 
             arial-label="sub-edit"
             iconType="indexEdit"
             color="danger"
             display="base"
             onClick={() => openEditFlyout(user)}
          />
        )
      }
    }
  
  ];

  React.useEffect(() => {
      getUserList();
  // eslint-disable-next-line react-hooks/exhaustive-deps     
  }, []);

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        backgroundColor: "#F7F8FC",
      }}
    >
      <Header />
      <EuiFlexGroup justifyContent="center" style={{ margin: "1rem" }}>
      <EuiFlexItem>
        <EuiPanel>
          <EuiBasicTable items={users} columns={columns} />
        </EuiPanel>
      </EuiFlexItem>
      </EuiFlexGroup>
      {showEditFlyout && (
      <EditsFlayout closeFlyout={closeEditFlyout} user={editUserSub} />
    )}
    </div>
  );
}

export default ManageUserSub