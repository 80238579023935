export const AfricaAllCountriesData = [
  {
    id: "nigeria",
    name: "Nigeria",
    imgLogo: "nigeria_flag.webp",
    subRegion: "Western Africa",
    devise: "NGN",
  },

  {
    id: "ethiopia",
    name: "Ethiopia",
    imgLogo: "ethiopia_flag.webp",
    subRegion: "Eastern Africa",
    devise: "ETB",
  },

  {
    id: "egypt",
    name: "Egypt",
    imgLogo: "egypt_flag.webp",
    subRegion: "Northern Africa",
    devise: "EGP",
  },

  {
    id: "dr congo",
    name: "DR Congo",
    imgLogo: "drc_flag.jpeg",
    subRegion: "Middle Africa",
    devise: "CDF",
  },

  {
    id: "tanzania",
    name: "Tanzania",
    imgLogo: "tanzania_flag.webp",
    subRegion: "Eastern Africa",
    devise: "TZS",
  },

  {
    id: "south africa",
    name: "South Africa",
    imgLogo: "south_africa_flag.webp",
    subRegion: "Southern Africa",
    devise: "ZAR",
  },

  {
    id: "kenya",
    name: "Kenya",
    imgLogo: "kenya_flag.webp",
    subRegion: "Eastern Africa",
    devise: "KES",
  },

  {
    id: "uganda",
    name: "Uganda",
    imgLogo: "uganda_flag.webp",
    subRegion: "Eastern Africa",
    devise: "UGX",
  },

  {
    id: "sudan",
    name: "Sudan",
    imgLogo: "sudan_flag.webp",
    subRegion: "Northern Africa",
    devise: "SDG",
  },

  {
    id: "morocco",
    name: "Morocco",
    imgLogo: "morocco_flag.webp",
    subRegion: "Northern Africa",
    devise: "MAD",
  },

  {
    id: "algeria",
    name: "Algeria",
    imgLogo: "algeria_flag.webp",
    subRegion: "Northern Africa",
    devise: "DZD",
  },

  {
    id: "angola",
    name: "Angola",
    imgLogo: "angola_flag.webp",
    subRegion: "Middle Africa",
    devise: "AOA",
  },

  {
    id: "ghana",
    name: "Ghana",
    imgLogo: "ghana_flag.webp",
    subRegion: "Western Africa",
    devise: "GHS",
  },

  {
    id: "mozambique",
    name: "Mozambique",
    imgLogo: "mozambique_flag.webp",
    subRegion: "Eastern Africa",
    devise: "MZN",
  },

  {
    id: "madagascar",
    name: "Madagascar",
    imgLogo: "madagascar_flag.webp",
    subRegion: "Eastern Africa",
    devise: "MGA",
  },

  {
    id: "ci",
    name: "Côte d'Ivoire",
    imgLogo: "ci_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "cameroon",
    name: "Cameroon",
    imgLogo: "cameroon_flag.webp",
    subRegion: "Middle Africa",
    devise: "XAF",
  },

  {
    id: "niger",
    name: "Niger",
    imgLogo: "niger_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "mali",
    name: "Mali",
    imgLogo: "mali_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "burkina faso",
    name: "Burkina Faso",
    imgLogo: "burkina_faso_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "malawi",
    name: "Malawi",
    imgLogo: "malawi_flag.webp",
    subRegion: "Eastern Africa",
    devise: "MWK",
  },

  {
    id: "zambia",
    name: "Zambia",
    imgLogo: "zambia_flag.webp",
    subRegion: "Eastern Africa",
    devise: "ZMW",
  },

  {
    id: "chad",
    name: "Chad",
    imgLogo: "chad_flag.webp",
    subRegion: "Middle Africa",
    devise: "XAF",
  },

  {
    id: "somalia",
    name: "Somalia",
    imgLogo: "somalia_flag.webp",
    subRegion: "Eastern Africa",
    devise: "SOS",
  },

  {
    id: "senegal",
    name: "Senegal",
    imgLogo: "senegal_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "zimbabwe",
    name: "Zimbabwe",
    imgLogo: "zimbabwe_flag.webp",
    subRegion: "Eastern Africa",
    devise: "ZWL",
  },

  {
    id: "guinea",
    name: "Guinea",
    imgLogo: "guinea_flag.webp",
    subRegion: "Western Africa",
    devise: "GNF",
  },

  {
    id: "rwanda",
    name: "Rwanda",
    imgLogo: "rwanda_flag.jpeg",
    subRegion: "Eastern Africa",
    devise: "RWF",
  },

  {
    id: "benin",
    name: "Benin",
    imgLogo: "benin_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "burundi",
    name: "Burundi",
    imgLogo: " burundi_flag.webp",
    subRegion: "Eastern Africa",
    devise: "BIF",
  },

  {
    id: "tunisia",
    name: "Tunisia",
    imgLogo: "tunisia_flag.webp",
    subRegion: "Northern Africa",
    devise: "TND",
  },

  {
    id: "south sudan",
    name: "South Sudan",
    imgLogo: "south-sudan-flag.webp",
    subRegion: "Eastern Africa",
    devise: "SSP",
  },

  {
    id: "togo",
    name: "Togo",
    imgLogo: "togo_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "sierra leone",
    name: "Sierra Leone",
    imgLogo: "sierra_leone_flag.webp",
    subRegion: "Western Africa",
    devise: "SLE",
  },

  {
    id: "libya",
    name: "Libya",
    imgLogo: "libya_flag.webp",
    subRegion: "Northern Africa",
    devise: "LYD",
  },

  {
    id: "congo",
    name: "Congo",
    imgLogo: "rep_of_the_congo_flag.webp",
    subRegion: "Middle Africa",
    devise: "XAF",
  },

  {
    id: "central african republic",
    name: "Central African Republic",
    imgLogo: "central_african_rep_flag.webp",
    subRegion: "Middle Africa",
    devise: "XAF",
  },

  {
    id: "liberia",
    name: "Liberia",
    imgLogo: "liberia_flag.webp",
    subRegion: "Western Africa",
    devise: "LRD",
  },

  {
    id: "mauritania",
    name: "Mauritania",
    imgLogo: "mauritania_flag.webp",
    subRegion: "Western Africa",
    devise: "MRU",
  },

  {
    id: "eritrea",
    name: "Eritrea",
    imgLogo: "eritrea_flag.webp",
    subRegion: "Eastern Africa",
    devise: "ERN",
  },

  {
    id: "gambia",
    name: "Gambia",
    imgLogo: "gambia_flag.webp",
    subRegion: "Western Africa",
    devise: "GMD",
  },

  {
    id: "botswana",
    name: "Botswana",
    imgLogo: "botswana_flag.webp",
    subRegion: "Southern Africa",
    devise: "BWP",
  },

  {
    id: "namibia",
    name: "Namibia",
    imgLogo: "namibia_flag.webp",
    subRegion: "Southern Africa",
    devise: "NAD",
  },

  {
    id: "gabon",
    name: "Gabon",
    imgLogo: "gabon_flag.webp",
    subRegion: "Middle Africa",
  },

  {
    id: "lesotho",
    name: "Lesotho",
    imgLogo: "lesotho_flag.webp",
    subRegion: "Southern Africa",
    devise: "LSL",
  },

  {
    id: "guinea-bissau",
    name: "Guinea-Bissau",
    imgLogo: "guinea-bissau_flag.webp",
    subRegion: "Western Africa",
    devise: "XOF",
  },

  {
    id: "equatorial guinea",
    name: "Equatorial Guinea",
    imgLogo: "equatorial_guinea_flag.webp",
    subRegion: "Middle Africa",
    devise: "XAF",
  },

  {
    id: "mauritius",
    name: "Mauritius",
    imgLogo: "mauritius_flag.webp",
    subRegion: "Eastern Africa",
    devise: "MUR",
  },

  {
    id: "eswatini",
    name: "Eswatini",
    imgLogo: "eswatini_flag.png",
    subRegion: "Southern Africa",
    devise: "EUR",
  },

  {
    id: "djibouti",
    name: "Djibouti",
    imgLogo: "djibouti_flag.webp",
    subRegion: "Eastern Africa",
    devise: "DJF",
  },

  {
    id: "comoros",
    name: "Comoros",
    imgLogo: "comoros_flag.webp",
    subRegion: "Eastern Africa",
    devise: "KMF",
  },

  {
    id: "cabo verde",
    name: "Cabo Verde",
    imgLogo: "cape_verde_flag.webp",
    subRegion: "Western Africa",
    devise: "CVE",
  },

  {
    id: "sao tome & principe",
    name: "Sao Tome & Principe",
    imgLogo: "sao_tome_principe_flag.webp",
    subRegion: "Middle Africa",
    devise: "STN",
  },

  {
    id: "seychelles",
    name: "Seychelles",
    imgLogo: "seychelles_flag.webp",
    subRegion: "Eastern Africa",
    devise: "SCR",
  },
];

export const ChinaAllProvinceData = [
  {
    id: "anhui",
    name: "Anhui (安徽)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "fujian",
    name: "Fujian (福建)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "hunan",
    name: "Hunan (湖南)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "gansu",
    name: "Gansu (甘肃)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "guangdong",
    name: "Guangdong (广东)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "guizhou",
    name: "Guizhou (贵州)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "",
  },
  {
    id: "jilin",
    name: "Jilin (吉林)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "hainan",
    name: "Hainan (海南)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "hebei",
    name: "Hebei (河北)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "heilongjiang",
    name: "Heilongjiang (黑龙江)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "",
  },
  {
    id: "henan",
    name: "Henan (河南)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },

  {
    id: "jiangsu",
    name: "Jiangsu (江苏)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "jiangxi",
    name: "Jiangxi (江西)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "liaoning",
    name: "Liaoning (辽宁)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "qinghai",
    name: "Qinghai (青海)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "shaanxi",
    name: "Shaanxi (陕西)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },

  {
    id: "shandong",
    name: "Shandong (山东)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "shanxi",
    name: "Shanxi (山西)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "sichuan",
    name: "Sichuan (四川)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "yunnan",
    name: "Yunnan (云南)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "zhejiang",
    name: "Zhejiang (浙江)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "guangxi zhuang ",
    name: "Guangxi Zhuang (广西壮族)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "inner mongolia",
    name: "Inner Mongolia (内蒙古)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "ningxia hui",
    name: "Ningxia Hui (宁夏回族)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "",
  },
  {
    id: "xinjiang uyghur",
    name: "Xinjiang Uyghur (新疆维吾尔)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "shanghai",
    name: "Shanghai (上海)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "chongqing",
    name: "Chongqing (重庆)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "tianjin",
    name: "Tianjin (天津)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "hong kong",
    name: "Hong Kong (香港)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "",
  },
  {
    id: "beijing",
    name: "Beijing (北京)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
  {
    id: "macao",
    name: "Macao (澳门)",
    imgLogo: "china_flag.png",
    subRegion: "Western Africa",
    devise: "CNY",
  },
];

export const ProductAllSize = [
  {
    type: "xs",
    name: "XS (Extra Small)",
  },
  {
    type: "s",
    name: "S (Small)",
  },
  {
    type: "m",
    name: "M (Medium)",
  },
  {
    type: "l",
    name: "L (Large)",
  },
  {
    type: "xl",
    name: "XL (Extra Large)",
  },
  {
    type: "xxl",
    name: "XXL (Double Extra Large)",
  },
  {
    type: "us",
    name: "US",
  },
  {
    type: "eu",
    name: "EU",
  },
  {
    type: "uk",
    name: "UK",
  },
  {
    type: "one size fits all",
    name: "One Size Fits All",
  },
  {
    type: "2t",
    name: "2T",
  },
  {
    type: "3t",
    name: "3T",
  },
  {
    type: "4t",
    name: "4T",
  },
  {
    type: "0",
    name: "0",
  },
  {
    type: "2",
    name: "2",
  },
  {
    type: "4",
    name: "4",
  },
  {
    type: "6",
    name: "6",
  },
  {
    type: "28",
    name: "28",
  },
  {
    type: "30",
    name: "30",
  },
  {
    type: "32",
    name: "32",
  },
  {
    type: "33",
    name: "33",
  },
  {
    type: "34",
    name: "34",
  },
  {
    type: "35",
    name: "35",
  },
  {
    type: "36",
    name: "36",
  },
  {
    type: "37",
    name: "37",
  },
  {
    type: "38",
    name: "38",
  },
  {
    type: "39",
    name: "39",
  },
  {
    type: "40",
    name: "40",
  },
  {
    type: "41",
    name: "41",
  },
  {
    type: "42",
    name: "42",
  },
  {
    type: "43",
    name: "43",
  },
  {
    type: "44",
    name: "44",
  },
  {
    type: "45",
    name: "45",
  },
  {
    type: "46",
    name: "46",
  },
  {
    type: "47",
    name: "47",
  },
  {
    type: "48",
    name: "48",
  },
  {
    type: "49",
    name: "49",
  },
  {
    type: "50",
    name: "50",
  },
  {
    type: "2 years",
    name: "2 Years",
  },
  {
    type: "4 years",
    name: "4 Years",
  },
  {
    type: "6years",
    name: "6 Years",
  },
  {
    type: "8 years",
    name: "8 Years",
  },
];

export const AfricaProductCategories = [
  {
    type: "all",
    name: "Show all",
  },

  {
    type: "agriculture",
    name: "Agricultural products",
  },
  {
    type: "mining",
    name: "Mining products",
  },
  {
    type: "arts",
    name: "Arts",
  },
  {
    type: "raw materials",
    name: "Raw materials",
  },
  {
    type: "commodities",
    name: "Commodities",
  },
  {
    type: "health",
    name: "Beauty & Health",
  },
  {
    type: "project",
    name: "⁠Investments Countries Government projects",
  },
];

export const ChinaProductCategories = [
  {
    type: "all",
    name: "Show all",
  },
  {
    type: "beauty",
    name: "Beauty",
  },
  {
    type: "electronics",
    name: "Electronics",
  },
  {
    type: "construction",
    name: "Construction",
  },
  {
    type: "Commodities",
    name: "Commodities",
  },
  {
    type: "apparel",
    name: "Fasion apparel & Accesories",
  },
  {
    type: "appliances",
    name: " ⁠Home Appliances & Kitchenware",
  },
  {
    type: "food",
    name: "Food & Agriculture",
  },
  {
    type: "machinery",
    name: "Machineries",
  },
  {
    type: "Health",
    name: "Health & Personal Care",
  },
];

export const AlgeriaTenProductCategories = [
  {
    type: "all",
    name: "Show all",
  },
  {
    type: "base",
    name: "Basic products",
  },
  {
    type: "electronics",
    name: "Electronics",
  },
  {
    type: "construction",
    name: "Construction",
  },
  {
    type: "raw materials",
    name: "Raw materials",
  },
  {
    type: "apparel",
    name: "Apparel",
  },
  {
    type: "food",
    name: "Food",
  },
  {
    type: "machinery",
    name: "Machinery",
  },
  {
    type: "health",
    name: "Beauty & Health",
  },
];
