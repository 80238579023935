import axios from "axios";
import { useState } from "react";

const useCreateSubTypeOfUser = () => {
  const [error, setError] = useState(null);

  const createSubTypeOfUser = async (
    selectUsers,
    selectSubType,
    startDate,
    endDate,
    navigate,
    id,
    createToast
  ) => {
    setError(null);

    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        "https://sinogani-api-server.onrender.com/api/createSubTypeOfUser",
        {
          email: selectUsers[0].email,
          subType: selectSubType[0].label,
          startDate: startDate.format("L"),
          endDate: endDate.format("L"),
        }
      );

      if (response.status === 200) {
        // Display success toast
        console.log("User Subscription Created Successfully.");
        createToast({
          title: "User Subscription Created Sucessfully.",
          type: "success",
        });

        navigate(`/admin/${id}`);
      }
    } catch (error) {
      console.error("Error creating subscription:", error);
    }
  };

  return { createSubTypeOfUser, error };
};

export default useCreateSubTypeOfUser;
