import React from "react";
import {
  AllProductNumberTitle,
  AllSearchFilterRightContainer,
  ProductListContentContainer,
  ProductListDatacrumbsContainer,
  ProductListGlobalContainer,
  ProductListTitle,
  ProductListUnder,
  SearchFilterContainer,
  TabsAddProduct,
  TabsAddText,
  TabsFilter,
  TabsFilterIcon,
  TabsProductHeadPNContainer,
  TabsProductHeadTitle,
  TabsProductHeadTitleContainer,
  TabsProductTitle,
  TabsSearchBar,
  TabsSearchIcon,
  TabsSearchInput,
  TabsSearchText,
} from "./ProductListPage.element";
import Pagination from "../Pagination";
import ModalProduct from "../ModalProduct";
import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import EmptyProductFeed from "../EmptyProductFeed";
import { ProductLoadingContainer } from "./CountryProduct.element";
import { Toaster } from "react-hot-toast";
import ModalProductEdition from "../ModalProductEdition";
import axios from "axios";

const ProductListPage = () => {
  const params = new URLSearchParams(window.location.search);

  const navigate = useNavigate();

  const icon = require("../../images/search.png");
  const sort = require("../../images/sort.png");

  const [productFiltered, setProductFiltered] = React.useState([]);
  const [searchApiData, setSearchApiData] = React.useState([]);
  const [productEdit, setProductEdit] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [filterVal, setFilterVal] = React.useState("");

  const itemsPerPage = 2;
  const uid = params.get("q");

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const updateModalOpen = (product) => {
    setModalOpen(true);

    setProductEdit(product);
  };

  const closeModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleUserLoadProducts = async () => {
    const uid = localStorage.getItem("uid");
    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        "https://sinogani-api-server.onrender.com/api/user/products",
        {
          params: { uid },
        }
      );
      setLoading(false);
      setProductFiltered(response.data);
      setSearchApiData(response.data);
      return response.data;
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchDataForPage = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    return productFiltered.slice(startIndex, endIndex);
  };

  const handleDeleteProductLocal = (id) => {
    setProductFiltered(productFiltered.filter((product) => product.uid !== id));
    setSearchApiData(searchApiData.filter((product) => product.uid !== id));
  };

  const handleUpdateproduct = (productId, newData) => {
    const updatedProducts = productFiltered.map((product) => {
      if (product.productId === productId) {
        return {
          ...product,
          ...newData,
        };
      } else {
        return product;
      }
    });

    // Mettez à jour le state avec la nouvelle liste de produits mise à jour
    setProductFiltered(updatedProducts);
  };

  const handleFilter = (e) => {
    if (e.target.value === "") {
      setProductFiltered(searchApiData);
    } else {
      const filterResult = searchApiData.filter((item) =>
        item.productName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setProductFiltered(filterResult);
    }

    setFilterVal(e.target.value);
  };

  React.useEffect(() => {
    handleUserLoadProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProductListGlobalContainer>
      <Toaster toastOptions={{ duration: 4000 }} />

      {modalOpen && (
        <ModalProductEdition
          updateProduct={handleUpdateproduct}
          product={productEdit}
          handleChangeModalState={closeModal}
          onDeleteProduct={handleDeleteProductLocal}
        />
      )}

      <ProductListContentContainer>
        <ProductListDatacrumbsContainer></ProductListDatacrumbsContainer>

        <ProductListTitle>Product management</ProductListTitle>
        <ProductListUnder>
          This section allows you to manage your products as well as their
          addition.
        </ProductListUnder>

        <SearchFilterContainer>
          <AllProductNumberTitle>
            All products{" "}
            <span>{productFiltered && productFiltered.length}</span>
          </AllProductNumberTitle>

          <AllSearchFilterRightContainer>
            <TabsSearchBar>
              <TabsSearchIcon src={icon} alt="Search Icon" />
              <TabsSearchInput
                value={filterVal}
                placeholder="Search"
                onInput={(e) => handleFilter(e)}
              />
            </TabsSearchBar>

            <TabsFilter>
              <TabsFilterIcon src={sort} alt="Filter Icon" />
              <TabsSearchText>Filters</TabsSearchText>
            </TabsFilter>

            <TabsAddProduct
              onClick={() => navigate(`/user/${uid}/add-product?q=${uid}`)}
            >
              <TabsAddText>Add product</TabsAddText>
            </TabsAddProduct>
          </AllSearchFilterRightContainer>
        </SearchFilterContainer>

        <TabsProductHeadTitleContainer>
          <TabsProductHeadPNContainer>
            <TabsProductTitle>Product name</TabsProductTitle>
          </TabsProductHeadPNContainer>

          <TabsProductHeadTitle>
            <TabsProductTitle>Price</TabsProductTitle>
          </TabsProductHeadTitle>

          <TabsProductHeadTitle>
            <TabsProductTitle>Category</TabsProductTitle>
          </TabsProductHeadTitle>

          <TabsProductHeadTitle>
            <TabsProductTitle>Product stock</TabsProductTitle>
          </TabsProductHeadTitle>
        </TabsProductHeadTitleContainer>

        {!loading ? (
          productFiltered.length !== 0 ? (
            fetchDataForPage().map((item) => (
              <ModalProduct
                handleChangeModalState={() => updateModalOpen(item)}
                key={item.uid}
                product={item}
              />
            ))
          ) : (
            <EmptyProductFeed />
          )
        ) : (
          <ProductLoadingContainer>
            <Oval height={100} width={100} color="black" />
          </ProductLoadingContainer>
        )}

        {productFiltered.length !== 0 && (
          <Pagination
            totalItems={productFiltered.length}
            itemsPerPage={itemsPerPage}
            onPageChange={handlePageChange}
          />
        )}
      </ProductListContentContainer>
    </ProductListGlobalContainer>
  );
};

export default ProductListPage;
